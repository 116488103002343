import pet from '@a/icons/pet.svg';
import { css } from '@emotion/react';

const styles = css`
    font-weight: 600;
    line-height: 162%;
`;

export const h1 = css`
    ${styles};
    font-size: 1.75rem;

    @media (min-width: 1280px) {
        font-size: 3.5rem;
    }
`;

export const h2 = css`
    line-height: 162%;
    font-size: 1.5rem;
    font-weight: 500;

    @media (min-width: 1280px) {
        font-size: 2.5rem;
    }
`;

export const h3 = css`
    line-height: 162%;
    font-size: 1.25rem;
    font-weight: 500;
    color: #353633;

    @media (min-width: 1280px) {
        font-size: 2rem;
    }
`;

export const h4 = css`
    ${styles};
    font-size: 1.125rem;
    font-weight: 600;
    color: #353633;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const h5 = css`
    ${styles};
    font-size: 1.0625rem;
    font-weight: 600;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;

export const smTxt = css`
    font-size: 0.875rem;
`;

export const lgTxt = css`
    font-size: 1.125rem;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;

export const txtLine = css`
    display: flex;
    align-items: center;
    color: #7293bc;
    font-weight: 400;
    font-family: 'Noto Serif', sans-serif;

    ::before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url(${pet});
        margin-right: 8px;
    }

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;
